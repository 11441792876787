<style>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="md" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="box.id === null">Electronic TF</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <b-row>
                    <b-col cols="12">
                        <!-- <b-alert show variant="danger" v-if="errosOcurs" class="p-1">{{ errosOcurs.message }}</b-alert> -->

                        <b-alert show variant="danger" v-if="errosOcurs">
                            <h4 class="alert-heading">
                                {{ errosOcurs.message }}
                            </h4>
                            <div class="alert-body">
                                <div v-for="(errosOcurs, field) in errosOcurs.errors" :key="field">
                                    <strong>{{ field }}:</strong>
                                    <ul>
                                        <li v-for="message in errosOcurs" :key="message">{{ message }}</li>
                                    </ul>
                                </div>
                            </div>
                        </b-alert>


                    </b-col>
                </b-row>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>

                            <b-col cols="12">
                                
                                <b-form-group labe-for="tfNumber" label="Electronic TF number">
                                    <validation-provider #default="{ errors }" name="Electronic TF" rules="">
                                        <b-form-input id="tfNumber" name="tfNumber" v-model="range.tfNumber"
                                            :state="errors.length > 0 ? false : null" size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.tfNumber">{{
                                            serverErrors.tfNumber[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                         
                        </b-row>

                        <b-row>
                            <b-col cols="6">
                                <h6>Local TF: </h6>
                            </b-col>
                            <b-col cols="6">
                                <h6>Bales: </h6>
                            </b-col>
                        </b-row>


                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Form Range Modal -->
        <b-modal id="modal-range-form" ref="myRangeModal" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submitUpdateRange">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Edit Range</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <b-col cols="12">
                        <!-- <b-alert show variant="danger" v-if="errosOcurs" class="p-1">{{ errosOcurs.message }}</b-alert> -->

                        <b-alert show variant="danger" v-if="errosOcurs">
                            <h4 class="alert-heading">
                                {{ errosOcurs.message }}
                            </h4>
                            <div class="alert-body">
                                <div v-for="(errosOcurs, field) in errosOcurs.errors" :key="field">
                                    <strong>{{ field }}:</strong>
                                    <ul>
                                        <li v-for="message in errosOcurs" :key="message">{{ message }}</li>
                                    </ul>
                                </div>
                            </div>
                        </b-alert>


                    </b-col>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>

                            <b-col cols="12">
                                <b-form-group labe-for="box_number" label="Electronic TF number">
                                    <validation-provider #default="{ errors }" name="Electronic TF" rules="">
                                        <b-form-input id="tfNumber" name="tfNumber" readonly v-model="range.tfNumber"
                                            :state="errors.length > 0 ? false : null" size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.tfNumber">{{
                                            serverErrors.tfNumber[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        

                     



                        <b-row v-if="range.hasMissing">
                            <b-col cols="5">
                                <b-form-group labe-for="lastBarcode" label="From Barcode" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="lastBarCode" rules="">
                                        <b-form-input id="lastBarcode" name="lastBarcode" v-model="range.lastBarcode"
                                            size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.lastBarcode">{{
                                            serverErrors.lastBarcode[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="5">
                                <b-form-group labe-for="direction" label="Direction" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="Gender" rules="">
                                        <b-form-select size="md" v-model="range.direction" id="direction" name="direction"
                                            :state="errors.length > 0 ? false : null">
                                            <b-form-select-option :value="null">-- Select
                                                --</b-form-select-option>
                                            <b-form-select-option value="1">After</b-form-select-option>
                                            <b-form-select-option value="0">Before</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.direction">{{
                                            serverErrors.direction[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="2">
                                <b-form-group labe-for="counts" label="Counts" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="count" rules="">
                                        <b-form-input id="count" type="number" name="count" v-model="range.count"
                                            size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.count">{{
                                            serverErrors.count[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>
<!-- 
                        <pre>
                            {{ range }}
                        </pre> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

             <!-- Filters -->
         
        <!-- ./Filters -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            
                            <b-button variant="primary" size="md" @click="invokeCreateForm()">
                                <span class="text-nowrap">Check TF Data</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import checkTfStoreModule from '@/views/cromis/green_shipping/check_tf/checkTfStoreModule'
import useCheckTfBoxesList from '@/views/cromis/green_shipping/check_tf/useCheckTfBoxesList'


export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox,BBadge,BAlert,
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const errosOcurs = ref(null)
        const levels = ref(null)
        const companies = ref([])
        const societies = ref([])
        const children = ref([])
        const box = ref({
            id: null,
            number: null,
        
        })

        const range = ref({
            id: null,
            box_id: null,
            number: null,
            start: null,
            end: null,
            quantity: null,
            hasMissing: false,
            lastBarcode: null,
            direction: null,
            count: null
        })



        const addElement = () => {
            box.value.ranges.push({ start: '', end: '', quantity: '' })

            if (box.value.ranges.length > 0) {


                console.log(box.value.ranges)
            }
        }

        const removeRow = (index) => {
            console.log(index)
            if (box.value.ranges.length > 1) {
                box.value.ranges.splice(index, 1)
                box.value.ranges = box.value.ranges
            }
        }


        const CROMIS_STORE_MODULE_NAME = 'cromis-box'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, checkTfStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
           
        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }


        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
            // Filers
            locationFilter,
        } = useCheckTfBoxesList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
            errosOcurs.value = null

            box.value = {
                id: item.id,
                type: item.type,
                number: item.number,
                ranges: item.ranges,
            }

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            box.value = {
                id: null,
                number: null,
               
            }

            myModal.value.show()
        }


        const invokeRangeUpdateForm = (par) => {
            formSelections.value.map((form) => {
                form.model = null
            })

            console.log(par)

            range.value = {
                id: par.ranges[0].id,
                box_id: par.id,
                number: par.number,
                start: par.ranges[0].start,
                end: par.ranges[0].end,
                quantity: par.ranges[0].quantity,
                hasMissing: null,
                lastBarcode: null,
                direction: null,
                count: null
            }

            myRangeModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (box.value.id === null || box.value.id === 0)
                handleCreate()
            else
                handleUpdate(box.value)
        }

        const submitUpdateRange = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleUpdateRange(range.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-box/create', box.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Box has been added successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

     

        const handleUpdateRange = async (item) => {
            console.log(item)
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-box/range', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to box range has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const handleUpdate = async (item) => {
            console.log(item)
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-box/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to box center ${response.data.code} ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            box,
            range,
            selectedOwner: null,
            addedElement: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: '-- Select Box Type --' },
                { value: '1', text: 'Bale Ticket' },
                { value: '2', text: 'Luggage' },
            ],
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,

            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myRangeModal,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            invokeRangeUpdateForm,
            submit,
            submitUpdateRange,
            isFormValid,
            handleCreate,
            handleUpdate,
            handleUpdateRange,
            addElement,
            removeRow,
            companies,
            societies,
            errosOcurs
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>